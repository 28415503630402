import React, { useState, useMemo } from 'react';

import {
  Box, Table, TableBody, TableCell, TableHead,
  TableRow, TableSortLabel, Button
} from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { visuallyHidden } from '@mui/utils';
import { HeadCell, NatData, FirewallData } from './Search';
import { json2csv } from 'json-2-csv';

interface TableDataProps {
  headCells: HeadCell[];
  data: NatData[] | FirewallData[];
  page: number;
  rowsPerPage: number;
  type: string;
  selectedService: { value: string; label: string } | null
}

type Order = 'asc' | 'desc';

interface EnhancedTableProps {
  order: Order;
  orderBy: keyof NatData | keyof FirewallData;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof NatData | keyof FirewallData) => void;
  headCells: HeadCell[];
  data: NatData[] | FirewallData[];
  selectedService: { value: string; label: string } | null
  type: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order, orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: FirewallData[] | NatData[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headCells, data, selectedService, type } = props;
  const createSortHandler = (property: keyof NatData | keyof FirewallData) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  // const handleExport = async () => {
  //   try {
  //     const fileName = selectedService ? `${type}_${selectedService.value}.csv` : 'data.csv'
  //     const parser = new Parser();
  //     const csv = parser.parse(data);
  //     const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  //     const url = URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.setAttribute('href', url);
  //     link.setAttribute('download', fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleExport = async () => {
    try {
      const fileName = selectedService ? `${type}_${selectedService.value}.csv` : 'data.csv';
      const csv = await json2csv(data); // Convert JSON data to CSV
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error exporting CSV:', err);
    }
  };
  
  

  return (
    <TableHead>
      <Button
      startIcon={<FileDownloadIcon />}
      onClick={handleExport}
      >
        Export as CSV
      </Button>
      <TableRow>
        {headCells.map((headCell: HeadCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const TableData: React.FC<TableDataProps> = ({ headCells, data,  page, rowsPerPage, type, selectedService}) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof NatData| keyof FirewallData>('name');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof NatData | keyof FirewallData) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const visibleRows = useMemo(
    () => stableSort(data, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [data, order, orderBy, page, rowsPerPage]
  );

  return (
    type === "NAT" ? (
      <Table>
      <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        headCells={headCells}
        data={data}
        selectedService={selectedService}
        type="NAT"
      />
      <TableBody>
        {visibleRows.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.state}</TableCell>
            <TableCell>{row.action}</TableCell>
            <TableCell>{row.internalIP}</TableCell>
            <TableCell>{row.externalIP}</TableCell>
            <TableCell>{row.application}</TableCell>
            <TableCell>{row.externalPort}</TableCell>
            <TableCell>{row.destinationIP}</TableCell>
            <TableCell>{row.log}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    ) : (
    <Table>
      <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        headCells={headCells}
        data={data}
        selectedService={selectedService}
        type="Firewall"
      />
      <TableBody>
        {visibleRows.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{row.state}</TableCell>
            <TableCell>{row.action}</TableCell>
            <TableCell>{row.log}</TableCell>
            <TableCell>{row.application}</TableCell>
            <TableCell>{row.source}</TableCell>
            <TableCell>{row.destination}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>)
  );
};

export default TableData;