import React, { useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import {
  Paper, TableContainer,
  TablePagination, Toolbar, Typography
} from '@mui/material';
// import { visuallyHidden } from '@mui/utils';
import TableData from './TableData';
import { json2csv } from 'json-2-csv';

type SearchProps = {
  placeholder: string;
  onSearch: (query: string) => void;
};

export interface NatData {
  name: string;
  state: string;
  action: string;
  internalIP: string;
  externalIP: string;
  application: string;
  externalPort: string;
  destinationIP: string;
  log: string;
}

export interface FirewallData {
  name: string;
  state: string;
  action: string;
  log: string;
  application: string;
  source: string;
  destination: string;
}

type Order = 'asc' | 'desc';

export interface HeadCell {
  disablePadding: boolean;
  id: keyof NatData | keyof FirewallData;
  label: string;
  numeric: boolean;
}

function createHeadCell(type: string): HeadCell[] {
  if(type === "NAT") {
    return (
      [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'state', numeric: false, disablePadding: false, label: 'State' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
        { id: 'internalIP', numeric: false, disablePadding: false, label: 'Internal IP' },
        { id: 'externalIP', numeric: false, disablePadding: false, label: 'External IP' },
        { id: 'application', numeric: false, disablePadding: false, label: 'Port' },
        { id: 'externalPort', numeric: false, disablePadding: false, label: 'External Port' },
        { id: 'destinationIP', numeric: false, disablePadding: false, label: 'Destination IP' },
        { id: 'log', numeric: false, disablePadding: false, label: 'Log' },
      ]
    )
  } else if(type === "Firewall") {
    return (
      [
        { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
        { id: 'state', numeric: false, disablePadding: false, label: 'State' },
        { id: 'action', numeric: false, disablePadding: false, label: 'Action' },
        { id: 'log', numeric: false, disablePadding: false, label: 'Log' },
        { id: 'application', numeric: false, disablePadding: false, label: 'Port' },
        { id: 'source', numeric: false, disablePadding: false, label: 'Source' },
        { id: 'destination', numeric: false, disablePadding: false, label: 'Destination' },
      ]
    )
  } else{
    return []
  }
}


export default function Search({ placeholder, onSearch }: SearchProps) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedService, setSelectedService] = useState<{ value: string; label: string } | null>(null);
  const [serviceOptionsInfo, setServiceOptionsInfo] = useState<any[]>([]);
  const [firewallID, setFirewallID] = useState("");
  const [data, setData] = useState<NatData[] | FirewallData[]>([]);
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof NatData>('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tableHeaderName, setTableHeaderName] = useState("NAT Information");
  const [type, setType] = useState("NAT");

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleServiceChange = (selectedOption: any) => {
    setSelectedService(selectedOption);
  };

  const handleCredentialCheck = async () => {
    try {
      const response = await axios.post('http://server-service:3001/api/checkCredentials', {
        username,
        password
      });
      if (response.data.success) {
        setIsAuthenticated(true);
        setError("");
        fetchServiceNames();
      } else {
        setIsAuthenticated(false);
        setError("Invalid username or password. Please try again.");
      }
    } catch (error: any) {
      console.error("Error checking credentials: ", error);
      setIsAuthenticated(false);
      setError("Error checking credentials. Please try again later.");
    }
  };

  const fetchServiceNames = async () => {
    try {
      const response = await axios.post('http://server-service:3001/api/getServiceNames', {
        username,
        password
      });
      const services = response.data.map((service: any) => ({ value: service, label: service }));
      
      setServiceOptions(services);
    } catch (error) {
      console.error("Error fetching service names: ", error);
    }
  };

  const handleSearch = async (type: string) => {
    console.log(username)
    if (!selectedService) {
      setError("Please select a service name.");
      return;
    }

    try {
      let data = serviceOptionsInfo;
      if(serviceOptionsInfo.length === 0) {
        const response = await axios.post('http://server-service:3001/api/getInfoData', {
          username,
          password
        });
        data = response.data.results;
        setServiceOptionsInfo(data)
      }
      
      const result = data.find((item: any) => item.display_name === selectedService.value);
      if (result) {
        if(type === 'NAT') {
          setType('NAT')
          setTableHeaderName("NAT Information")
          fetchNATInfo(result.id);
        } else if(type === 'Firewall') {
          setType('Firewall')
          setTableHeaderName('Firewall Information')
          fetchFirewallInfo(result.id);
        }
        setError("");
      } else {
        setData([])
        setError("Service name not found. Please try again.");
      }
    } catch (error: any) {
      setData([])
      console.error("Error fetching data: ", error);
      if (error.response && error.response.status === 401) {
        setError("Invalid username or password. Please try again.");
      } else {
        setError("Error fetching data. Please try again later.");
      }
    }
  };

  const fetchNATInfo = async (firewallId: string) => {
    try {
      const response = await axios.post('http://server-service:3001/api/getNATInfo', {
        username,
        password,
        firewallId
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching NAT information: ", error);
    }
  };

  const fetchFirewallInfo = async (firewallId: string) => {
    try {
      const response = await axios.post('http://server-service:3001/api/getFirewallInfo', {
        username,
        password,
        firewallId
      });
      setData(response.data);
    } catch (error) {
      console.error("Error fetching Firewall information: ", error);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExport = async () => {
    try {
      const service = selectedService?.value; // Assuming selectedService is defined elsewhere
      const csv = await json2csv(data); // Convert JSON data to CSV
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', `${service}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      console.error('Error exporting CSV:', err);
    }
  };

  return (
    <div className="flex flex-col justify-center items-center w-full mt-10 space-y-16">
      {!isAuthenticated ? (
        <div className="bg-white bg-opacity-10 backdrop-blur-sm p-6 rounded-[20px] flex flex-col items-center w-[550px] sm:w-full gap-6">
          <h2 className="w-full text-white text-[18px] -mb-[5px]">Enter your account ID</h2>
              <input
                id="username"
                type="text"
                value={username}
                onChange={handleUsernameChange}
                className="text-white bg-transparent w-full border border-1 py-[9px] px-[15px] rounded-[15px]"
                placeholder="Enter your username"
              />
              <input
                id="password"
                type="password"
                value={password}
                onChange={handlePasswordChange}
                className="text-white bg-transparent w-full border border-1 py-[9px] px-[15px] rounded-[15px]"
                placeholder="Enter your password"
              />
            {error && <p className="text-red-500 mt-2">{error}</p>}
            <button
              onClick={handleCredentialCheck}
              className="bg-gradient-to-r from-cyan-500 to-red-500 hover:from-cyan-600 hover:to-red-600 text-white font-bold py-[9px] px-[15px] rounded-[15px]  w-full text-left"
            >
              Check credentials
            </button>
        </div>
      ) : (
        <>
          <div className="relative z-10 bg-white bg-opacity-10 backdrop-blur-sm p-6 rounded-[20px] flex flex-col gap-6 items-center w-[550px] sm:w-full">
            <p className="w-full text-white text-[18px] -mb-[5px]">Select a service</p>
            <Select
              options={serviceOptions}
              value={selectedService}
              onChange={handleServiceChange}
              className="w-full"
              placeholder="Select a service"
            />
            <div className='flex w-full gap-[15px] sm:flex-col'>
              <button
                onClick={()=>handleSearch('NAT')}
                className="text-center bg-gradient-to-r from-cyan-500 to-red-500 hover:from-cyan-600 hover:to-red-600 text-white font-bold py-[9px] px-[15px] rounded-[15px] w-full text-left "
              >
                Show NAT Information
              </button>
              <button
                onClick={()=>handleSearch('Firewall')}
                className=" text-center bg-gradient-to-r from-cyan-500 to-red-500 hover:from-cyan-600 hover:to-red-600 text-white font-bold py-[9px] px-[15px] rounded-[15px] w-full text-left"
              >
                Show Firewall Information
              </button>
            </div>
            
          </div>

          <div className="rounded-[4px] w-full overflow-auto">
            <Paper className="w-[1500px]">
              <Toolbar>
                <Typography
                  sx={{ flex: '1 1 100%', fontWeight: 'bold'}}
                  variant="h6"
                  id="tableTitle"
                  component="div"
                >
                  {tableHeaderName}
                </Typography>
              </Toolbar>
              <TableContainer>
                <TableData headCells={createHeadCell(type)} data={data} page={page} rowsPerPage={rowsPerPage} type={type} selectedService={selectedService} />
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </div>
        </>
      )}
    </div>
  );
}
