import React from 'react';
import logo from '../../images/logo-cloud.svg';

export default function Header() {
  return (
    <header className="absolute top-5 left-10 md:left-[30px]">
        <img src={logo} alt="Cloud HM" />
    </header>
  );
}




