import React, { useState, useEffect } from 'react';
import Search from './components/Search';
import Header from './components/Header';
import Background from './components/Background';

const randomWordList = [
  "✨ May the cloud be with you ☁️",
  "🍑 Hello from Micky 🍑",
  "🌍 Hello World 🌍",
  "🍀 Have a nice day ✌️",
  "🧋 Stay hydrated 🍵",
  "🍻 Is it Friday yet? 🥳",
  "🍪 Coffee first, Work later ☕️"
];

function Homepage() {
  const [randomWord, setRandomWord] = useState("");

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * randomWordList.length);
    setRandomWord(randomWordList[randomIndex]);
  }, []);

  return (
    <div className="relative w-full min-h-screen flex justify-center items-center p-[100px] md:px-[30px] md:pb-[40px]">
      <Header />
      <div className="flex flex-col justify-center items-center w-full">
        <h1 className="text-4xl md:text-3xl bf-xs:text-2xl text-white font-medium">{randomWord}</h1>
        <Search placeholder="Service name" onSearch={(query) => console.log(query)} />
      </div>
    </div>
  );
}

export default Homepage;
